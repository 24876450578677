import { Component } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { SharedService } from './_services/shared.service'
import { RouterOutlet } from '@angular/router'

@Component({
    selector: 'app-root',
    template: ` <router-outlet></router-outlet> `,
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent {
    constructor(private titleService: Title, private sharedService: SharedService) {
        const host = window.location.host
        let envString = host.includes('develop.') ? 'DEVELOP | ' : ''
        if (host.includes('localhost:')) envString = 'LOCAL | '
        this.titleService.setTitle(`${envString}HMG Dashboard`)
        const companyNameId = host.includes('hollandmovers.nl') ? 'hm' : 'svs'
        this.sharedService.faviconSet(companyNameId)
        this.unregisterServiceWorker()
    }

    unregisterServiceWorker() {
        if (typeof navigator !== 'undefined' && 'serviceWorker' in navigator) {
            navigator.serviceWorker
                .getRegistrations()
                .then(function (registrations) {
                    for (const registration of registrations) {
                        registration.unregister()
                        console.log('ServiceWorker unregistered')
                    }
                })
                .catch(function (err) {
                    console.error('ServiceWorker unregistration failed: ', err)
                })
        }
    }
}
